<template>
  <div>
    <v-overlay :z-index="100" :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="grey darken-2"
          indeterminate
      ></v-progress-circular>
    </v-overlay>

    <div class="text-h5">Accounts</div>

    <!-- START BUTTONS -->
    <div class="text-right mb-5">
      <v-btn
          color="primary"
          class="mt-2"
          @click="addAccount()"
      >
        Add Account
        <v-icon class="pl-3" dark>
          mdi-plus
        </v-icon>
      </v-btn>

      <v-btn
          color="primary"
          class="ml-2 mt-2"
          @click="transferAmount()"
      >
        Transfer Between Accounts
        <v-icon class="pl-3" dark>
          mdi-transfer
        </v-icon>
      </v-btn>
    </div>
    <!-- END BUTTONS -->

    <!-- Account list -->
    <v-row>
      <v-col cols="12" md="4" v-for="account in filteredData" :key="'account-' + account.id">
        <v-card elevation="10">
          <v-card-title>{{ account.title }}</v-card-title>
          <v-card-subtitle>{{ account.description }}</v-card-subtitle>
          <v-card-text><div class="text-h6">{{ account.balance|formatAmount }}</div></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="editAccount(account)"
            >
              <v-icon small class="mr-2">mdi-pencil</v-icon>
              Edit
            </v-btn>

            <v-btn
                color="blue darken-1"
                text
                @click="deleteAccount(account.id)"
            >
              <v-icon small>mdi-delete</v-icon>
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- START ADD/EDIT CARD -->
    <v-dialog
        v-model="editMode"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Account Details</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="9"
              >
                <v-text-field
                    label="Title *"
                    required
                    v-model="editData.title"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="3"
              >
                <v-text-field
                    prepend-inner-icon="mdi-cash"
                    label="Balance *"
                    required
                    v-model="editData.balance"
                    type="number"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                    label="Description *"
                    required
                    v-model="editData.description"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="editMode = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveAccount()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END ADD/EDIT CARD -->

    <!-- START TRANSFER AMOUNT CARD -->
    <v-dialog
        v-model="transferMode"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Transfer Between Accounts</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
              >
                <v-select
                    :items="sourceAccounts"
                    item-value="id"
                    item-text="title"
                    v-model="transferData.source_id"
                    label="Source Account*"
                    placeholder="Select account"
                />
              </v-col>

              <v-col
                  cols="12"
                  md="9"
              >
                <v-select
                    :items="targetAccounts"
                    item-value="id"
                    item-text="title"
                    v-model="transferData.dest_id"
                    label="Target Account*"
                    placeholder="Select account"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                    label="Amount *"
                    required
                    v-model="transferData.amount"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="transferMode = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="submitTransfer()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END TRANSFER AMOUNT CARD -->
  </div>
</template>

<script>
import { formatAsCurrency } from "../../services/utils";

export default {
  name: "Accounts",
  data () {
    return {
      panels: [],
      pagingOptions: [10,25,50,100,-1],
      mobileWidth: 767,
      editData: {
        id: 0,
        title: '',
        description: '',
        balance: '',
      },
      editMode: false,
      transferMode: false,
      transferData: {
        source_id: 0,
        dest_id: 0,
        amount: 0
      }
    }
  },

  mounted() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
      case 'sm':
        this.panels = [];
        break;
      case 'md':
      case 'lg':
      case 'xl':
        this.panels = [0,1];
        break;
    }

    this.$store.dispatch('fetchAccounts');
  },

  computed: {
    accounts() {
      return this.$store.state.accounts.accounts;
    },

    loading() {
      return this.$store.state.accounts.loading;
    },

    checkIsDesktop () {
      return window.matchMedia(`(min-width: ${this.mobileWidth}px)`).matches
    },

    filteredData() {
      return this.accounts;
    },

    sourceAccounts() {
      return this.accounts.filter((acc) => {
        return acc.id !== this.transferData.dest_id;
      });
    },

    targetAccounts() {
      return this.accounts.filter((acc) => {
        return acc.id !== this.transferData.source_id;
      });
    },

    totalAmount() {
      let total = 0;
      this.filteredData.forEach((r) => total += r.raw_amount);
      return Math.round(total * 100) / 100;
    },

    formattedTotalAmount() {
      return formatAsCurrency(this.totalAmount);
    }
  },

  filters: {
    formatAmount(amount) {
      return formatAsCurrency(amount);
    }
  },

  methods: {
    addAccount() {
      this.editData = {
        id: 0,
        title: '',
        description: '',
        balance: '',
      }

      this.editMode = true;
    },

    saveAccount() {
      if (this.editData.id < 1) {
        this.$store.dispatch('addAccount', this.editData);
      } else {
        this.$store.dispatch('updateAccount', this.editData);
      }
      this.editMode = false;
    },

    editAccount(item) {
      this.editData.id = item.id;
      this.editData.description = item.description;
      this.editData.balance = item.balance;
      this.editData.title = item.title;

      this.editMode = true;
    },

    deleteAccount(id) {
      this.$store.dispatch('deleteAccount', id);
    },

    transferAmount() {
      this.transferData = {
        source_id: 0,
        dest_id: 0,
        amount: 0
      }

      this.transferMode = true;
    },

    submitTransfer() {
      this.$store.dispatch('transferAmount', this.transferData);

      this.transferMode = false;
    },

  }
}
</script>

<style scoped>

</style>